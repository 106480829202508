var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-32"},[_c('h4',{staticClass:"md:w-1024 px-4 mx-auto md:px-0 text-left text-sm text-secondary font-semibold uppercase tracking-widest cursor-pointer",on:{"click":function($event){return _vm.$router.push('/certification')}}},[_vm._v(" < previous page ")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"md:w-1024 mx-auto py-12 px-4 md:px-0 border-t flex flex-col-reverse md:flex-row items-center justify-between"},[_c('div',{staticClass:"w-5/12 p-6 rounded-lg bg-cover",style:(`background-image: url(${_vm.imageUrl})`)},[_c('LottieAnimationVue',{staticClass:"h-72",attrs:{"animationData":_vm.animationData}})],1),_vm._m(4)]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:w-1024 mx-auto py-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"},[_c('div',{staticClass:"w-full md:w-10/12 pr-6 text-left text-textBlack"},[_c('h1',{staticClass:"mb-4 text-4xl text-primary font-semibold"},[_vm._v("neoAssess")]),_c('h3',{staticClass:"w-max text-xl text-textBlack font-semibold"},[_vm._v(" Longitudinal English Assessment ")]),_c('p',{staticClass:"my-8 text-textBlack text-justify"},[_vm._v(" neoAssess evaluates test-takers English skills with precision at both initial assessments​ and at regular intervals. You can disable test-taker access as needed, and enable it again when it's time for the next test.​ ")]),_c('h3',{staticClass:"mb-6 w-max text-xl text-textBlack font-semibold"},[_vm._v(" 30+ Years Assessing Ells Worldwide ")]),_c('p',{staticClass:"mt-6 bg-softGrey text-textBlack text-justify font-semibold p-4 italic"},[_vm._v(" Results Are Fully Aligned To Standards Set By The Common European Framework Of Reference For Languages (CEFR), And Correlates To All Other International Standards Like TOEFL, IELTS, And TOEICTM. ")])]),_c('img',{attrs:{"src":require("@/assets/images/icons/assess_icon.png"),"alt":"assess_icon"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-softGrey"},[_c('div',{staticClass:"md:w-1024 mx-auto py-12 px-4 md:px-0 flex flex-col-reverse md:flex-row items-center justify-between"},[_c('div',{staticClass:"w-full md:w-6/12 text-left"},[_c('h1',{staticClass:"text-4xl text-primary font-semibold"},[_vm._v(" neo’s Adaptive Assessment ensures accurate results every time​ ")]),_c('p',{staticClass:"mt-4 text-textBlack text-justify"},[_vm._v(" neoAssess verifies candidates' English language proficiency by comparing their answers to the required “Can Do” statements for each of the levels of the CEFR. ")])]),_c('img',{staticClass:"w-5/12",attrs:{"src":require("@/assets/images/icons/girl_laptop.png"),"alt":"girl_laptop"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:w-1024 mx-auto py-12 px-4 md:px-0 flex flex-col-reverse md:flex-row items-center justify-between"},[_c('img',{staticClass:"w-5/12",attrs:{"src":require("@/assets/images/icons/assess_icon.png"),"alt":"assess_icon"}}),_c('div',{staticClass:"w-full md:w-6/12 text-left"},[_c('h4',{staticClass:"mb-4 text-left text-sm font-semibold text-textBlack uppercase tracking-widest cursor-pointer"},[_vm._v(" REPORTING ")]),_c('h1',{staticClass:"text-4xl text-primary font-semibold"},[_vm._v("neoAssess Reporting")]),_c('p',{staticClass:"mt-4 text-textBlack text-justify"},[_vm._v(" neoAssess Reports keep you on track to achieve your goals. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:w-1024 mx-auto py-12 px-4 md:px-0 border-t flex flex-col-reverse md:flex-row items-center justify-between"},[_c('div',{staticClass:"w-full md:w-6/12 text-left"},[_c('h4',{staticClass:"mb-6 text-left font-semibold text-textBlack uppercase tracking-widest cursor-pointer"},[_vm._v(" DASHBOARD ")]),_c('h1',{staticClass:"text-5xl text-primary font-semibold"},[_vm._v(" neo’s Universal Dashboard ")]),_c('h5',{staticClass:"pt-4 pb-2 text-textBlack"},[_vm._v(" Monitor activity on neo’s intuitive Dashboard. ")]),_c('ul',{staticClass:"list-disc text-textBlack text-left"},[_c('li',[_vm._v("Secure login")]),_c('li',[_vm._v("Results in real time")]),_c('li',[_vm._v("View by key groupings or individuals")]),_c('li',[_vm._v("Advanced Reporting")]),_c('li',[_vm._v(" Teacher's have access to Recommended Classroom Activities (RCAs) ")])])]),_c('img',{staticClass:"h-80",attrs:{"src":require("@/assets/images/MicrosoftTeams_image_student.png"),"alt":"MicrosoftTeams-image-student"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full md:w-6/12 text-left"},[_c('h4',{staticClass:"mb-4 text-left text-sm font-semibold text-textBlack uppercase tracking-widest cursor-pointer"},[_vm._v(" setup ")]),_c('h1',{staticClass:"text-4xl text-primary font-semibold"},[_vm._v(" Setting up is effortless ")]),_c('p',{staticClass:"mt-4 text-textBlack text-justify"},[_vm._v(" Uploading test-takers with the click of a few buttons makes neoAssess easy. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-softGrey"},[_c('div',{staticClass:"w-full md:w-6/12 py-12 mx-auto text-center"},[_c('h4',{staticClass:"mb-4 text-sm font-semibold text-textBlack uppercase tracking-widest cursor-pointer"},[_vm._v(" TEST TAKERS ")]),_c('h1',{staticClass:"text-4xl text-primary font-semibold"},[_vm._v(" You choose how "),_c('br'),_vm._v(" test-takers view results ")]),_c('img',{attrs:{"src":require("@/assets/images/icons/phone_laptop_icon.png"),"alt":"phone_laptop_icon"}}),_c('p',{staticClass:"text-justify mx-auto text-textBlack"},[_vm._v(" After taking the test, users can view their test score immediately, be sent to a website or URL location that will provide users with more information on when they will get their results. ")])])])
}]

export { render, staticRenderFns }