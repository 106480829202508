<template>
  <div class="pt-32">
    <h4
      class="md:w-1024 px-4 mx-auto md:px-0 text-left text-sm text-secondary font-semibold uppercase tracking-widest cursor-pointer"
      @click="$router.push('/certification')"
    >
      &#60; previous page
    </h4>
    <div
      class="md:w-1024 mx-auto py-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
    >
      <div class="w-full md:w-10/12 pr-6 text-left text-textBlack">
        <h1 class="mb-4 text-4xl text-primary font-semibold">neoAssess</h1>
        <h3 class="w-max text-xl text-textBlack font-semibold">
          Longitudinal English Assessment
        </h3>
        <p class="my-8 text-textBlack text-justify">
          neoAssess evaluates test-takers English skills with precision at both
          initial assessments​ and at regular intervals. You can disable
          test-taker access as needed, and enable it again when it's time for
          the next test.​
        </p>
        <h3 class="mb-6 w-max text-xl text-textBlack font-semibold">
          30+ Years Assessing Ells Worldwide
        </h3>
        <p class="mt-6 bg-softGrey text-textBlack text-justify font-semibold p-4 italic">
          Results Are Fully Aligned To Standards Set By The Common European
          Framework Of Reference For Languages (CEFR), And Correlates To All
          Other International Standards Like TOEFL, IELTS, And TOEICTM.
        </p>
      </div>
      <img src="@/assets/images/icons/assess_icon.png" alt="assess_icon" />
    </div>
    <div class="bg-softGrey">
      <div
        class="md:w-1024 mx-auto py-12 px-4 md:px-0 flex flex-col-reverse md:flex-row items-center justify-between"
      >
        <div class="w-full md:w-6/12 text-left">
          <h1 class="text-4xl text-primary font-semibold">
            neo’s Adaptive Assessment ensures accurate results every time​
          </h1>
          <p class="mt-4 text-textBlack text-justify">
            neoAssess verifies candidates' English language proficiency by
            comparing their answers to the required “Can Do” statements for each
            of the levels of the CEFR.
          </p>
        </div>
        <img
          class="w-5/12"
          src="@/assets/images/icons/girl_laptop.png"
          alt="girl_laptop"
        />
      </div>
    </div>
    <div
      class="md:w-1024 mx-auto py-12 px-4 md:px-0 flex flex-col-reverse md:flex-row items-center justify-between"
    >
      <img
        class="w-5/12"
        src="@/assets/images/icons/assess_icon.png"
        alt="assess_icon"
      />
      <div class="w-full md:w-6/12 text-left">
        <h4
          class="mb-4 text-left text-sm font-semibold text-textBlack uppercase tracking-widest cursor-pointer"
        >
          REPORTING
        </h4>
        <h1 class="text-4xl text-primary font-semibold">neoAssess Reporting</h1>
        <p class="mt-4 text-textBlack text-justify">
          neoAssess Reports keep you on track to achieve your goals.
        </p>
      </div>
    </div>
    <div
      class="md:w-1024 mx-auto py-12 px-4 md:px-0 border-t flex flex-col-reverse md:flex-row items-center justify-between"
    >
      <div class="w-full md:w-6/12 text-left">
        <h4
          class="mb-6 text-left font-semibold text-textBlack uppercase tracking-widest cursor-pointer"
        >
          DASHBOARD
        </h4>
        <h1 class="text-5xl text-primary font-semibold">
          neo’s Universal Dashboard
        </h1>
        <h5 class="pt-4 pb-2 text-textBlack">
          Monitor activity on neo’s intuitive Dashboard.
        </h5>
        <ul class="list-disc text-textBlack text-left">
          <li>Secure login</li>
          <li>Results in real time</li>
          <li>View by key groupings or individuals</li>
          <li>Advanced Reporting</li>
          <li>
            Teacher's have access to Recommended Classroom Activities (RCAs)
          </li>
        </ul>
      </div>
      <!-- <img
        class="w-5/12"
        src="@/assets/images/icons/man_laptop.png"
        alt="man_laptop"
      /> -->
      <img
        class="h-80"
        src="@/assets/images/MicrosoftTeams_image_student.png"
        alt="MicrosoftTeams-image-student"
      />
    </div>
    <div
      class="md:w-1024 mx-auto py-12 px-4 md:px-0 border-t flex flex-col-reverse md:flex-row items-center justify-between"
    >
      <div
        class="w-5/12 p-6 rounded-lg bg-cover"
        :style="`background-image: url(${imageUrl})`"
      >
        <LottieAnimationVue class="h-72" :animationData="animationData" />
      </div>
      <div class="w-full md:w-6/12 text-left">
        <h4
          class="mb-4 text-left text-sm font-semibold text-textBlack uppercase tracking-widest cursor-pointer"
        >
          setup
        </h4>
        <h1 class="text-4xl text-primary font-semibold">
          Setting up is effortless
        </h1>
        <p class="mt-4 text-textBlack text-justify">
          Uploading test-takers with the click of a few buttons makes neoAssess
          easy.
        </p>
      </div>
    </div>
    <div class="bg-softGrey">
      <div class="w-full md:w-6/12 py-12 mx-auto text-center">
        <h4
          class="mb-4 text-sm font-semibold text-textBlack uppercase tracking-widest cursor-pointer"
        >
          TEST TAKERS
        </h4>
        <h1 class="text-4xl text-primary font-semibold">
          You choose how <br />
          test-takers view results
        </h1>
        <img
          src="@/assets/images/icons/phone_laptop_icon.png"
          alt="phone_laptop_icon"
        />
        <p class="text-justify mx-auto text-textBlack">
          After taking the test, users can view their test score immediately, be
          sent to a website or URL location that will provide users with more
          information on when they will get their results.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import LottieAnimationVue from "../components/lottie/LottieAnimation.vue";

export default {
  components: {
    LottieAnimationVue,
  },
  data() {
    return {
      imageUrl: require("@/assets/images/bgspace.png"),
      purpleColor: "#D6E0FF",
      greenColor: "#E5FFC6",
      orangeColor: "#FFF1D6",
      turquoiseColor: "#D6FFF9",
      whiteColor: "#FCFCFC",
      animationData: require("@/assets/json/setup_animation.json"), // Import the Lottie JSON animation data
    };
  },
};
</script>

<style scoped>
</style>